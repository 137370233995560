import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Img from 'gatsby-image';

import './ExplanationSection.css';

const ExplanationSection = (props) => {
  if (!props.content?.explanation?.headline || !props.content?.explanation?.text) {
    return null;
  }

  return (
    <section className="section-content accent-soft">
      <Container fluid>
        <h2 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.explanation.headline }} />
        <div className="explanation-container">
          <Row>
            <Col xs={12} lg={6} className="d-xs-none d-md-none d-lg-block">
              {props.content.explanation.image.extension === 'svg' ? (
                <img src={props.content.explanation.image.publicURL} alt={props.content.meta.name} className="w-100" />
              ) : (
                <Img fluid={props.content.explanation.image.childImageSharp.fluid} alt={props.content.meta.name} />
              )}
            </Col>
            <Col xs={12} lg={6} className="d-flex align-items-center">
              <span dangerouslySetInnerHTML={{ __html: props.content.explanation.text }} className="explanation-text" />
            </Col>
            <Col xs={12} lg={6} className="d-lg-none explanation-image">
              {props.content.explanation.image.extension === 'svg' ? (
                <img
                  src={props.content.explanation.image.publicURL}
                  alt={props.content.meta.name}
                  className="w-100"
                  style={{ padding: '1em' }}
                />
              ) : (
                <Img fluid={props.content.explanation.image.childImageSharp.fluid} alt={props.content.meta.name} />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ExplanationSection;
