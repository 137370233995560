import React, { useState } from 'react';
import Img from 'gatsby-image';
import { FormattedMessage, Link as LinkIntl } from 'gatsby-plugin-intl';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import bullets from '../../config/colors/bullets';

import './AccordionSection.css';

// TODO refactcor this component to one with ServiceSummary used on index page
const AccordionSection = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeAccordion = props.content.accordion.elements[activeIndex];

  const clickHandler = (index) => setActiveIndex(index);

  return (
    <section className="section-content">
      <Container id="accordion-summary-container" fluid>
        <h2
          className="section-headline underline"
          dangerouslySetInnerHTML={{ __html: props.content.accordion.headline }}
        />

        <div className="accordion-summary-row">
          <Row>
            <Col lg={6} className="d-xs-none d-sm-none d-md-none d-lg-block m-auto">
              <Img
                className="w-100 h-100 accordion-portfolio-image"
                fluid={activeAccordion.image.childImageSharp.fluid}
                alt={activeAccordion.name}
              />
            </Col>
            <Col lg={6}>
              <div className="accordion-summary-buttons">
                {props.content?.accordion?.elements.map((element, index) => (
                  <div
                    className={
                      index === activeIndex ? 'accordion-summary-button' : 'accordion-summary-button-collapsed'
                    }
                    key={`accordion-summary-button-${index}`}
                    role="button"
                    onClick={() => clickHandler(index)}
                  >
                    <div className="accordion-summary-button-text">
                      <div className="accordion-progress">
                        <div className="accordion-progress-bar" style={{ background: bullets[index % 3].color }} />
                      </div>
                      <div className="accordion-title-wrapper">
                        <h3 dangerouslySetInnerHTML={{ __html: element.name }} />
                        {activeIndex !== index && (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="accordion-title-chevron"
                            style={{ marginLeft: '1rem' }}
                          />
                        )}
                        {activeIndex === index && (
                          <FontAwesomeIcon
                            icon={faChevronUp}
                            className="accordion-title-chevron"
                            style={{ marginLeft: '1rem' }}
                          />
                        )}
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: element.text }} />
                      {element.url && element.url_external && (
                        <a href={element.url} target="_blank" rel="noreferrer">
                          <Button variant="link" className="accordion-summary-button-link">
                            <FormattedMessage id="buttons.learn_more" />
                            <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '.75rem' }} />
                          </Button>
                        </a>
                      )}
                      {element.url && !element.url_external && (
                        <LinkIntl to={element.url}>
                          <Button variant="link" className="accordion-summary-button-link">
                            <FormattedMessage id="buttons.learn_more" />
                            <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '.75rem' }} />
                          </Button>
                        </LinkIntl>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default AccordionSection;
