import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import Landing from '../components/Landing/Landing';
import UspSection from '../components/UspSection/UspSection';
import AccordionSection from '../components/AccordionSection/AccordionSection';
import CallToActionSection from '../components/CallToActionSection/CallToActionSection';
import SkillSection from '../components/SkillSection/SkillSection';
import ContactForm from '../components/ContactForm/ContactForm';
import ExplanationSection from '../components/ExplanationSection/ExplanationSection';
import TestimonialsSummary from '../components/TestimonialsSummary/TestimonialsSummary';

export default function Template({ data }) {
  // TODO find a proper solution to prevent creation of en-pages with de-slugs and vice versa
  if (!data.content || !data.contentTestimonials?.nodes?.length) {
    return null;
  }

  const content = data.content.frontmatter;
  const contentTestimonials = data.contentTestimonials.nodes[0].frontmatter;

  return (
    <Layout>
      <SEO title={content.meta.seo_title} description={content.meta.seo_description} />
      <Landing content={content} borderImg decoration />
      <ExplanationSection content={content} />
      <UspSection content={content} />
      <AccordionSection content={content} />
      <CallToActionSection content={content} />
      <TestimonialsSummary content={contentTestimonials} />
      <SkillSection content={content} />
      <ContactForm content={content} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($language: String!, $slug: String!) {
    content: markdownRemark(
      collection: { eq: "pages" }
      folder: { eq: "services" }
      fields: { language: { eq: $language }, slug: { eq: $slug } }
    ) {
      fields {
        language
        slug
      }
      frontmatter {
        meta {
          name
          slug
          seo_title
          seo_description
        }
        hero {
          headline
          text
          image {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_tablet {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          bullets {
            name
            color
            background
          }
        }
        explanation {
          headline
          text
          image {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        usp {
          headline
          text
          elements {
            name
            text
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        accordion {
          headline
          text
          elements {
            name
            text
            url
            url_external
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        call_to_action {
          headline
          text
        }
        skills {
          headline
          text
          elements {
            name
            image {
              publicURL
              extension
              childImageSharp {
                fluid(maxHeight: 128, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            url
          }
        }
        contact {
          headline
          text
        }
      }
    }
    contentTestimonials: allMarkdownRemark(
      filter: { collection: { eq: "pages" }, folder: { eq: "index" }, fields: { language: { eq: $language } } }
    ) {
      nodes {
        frontmatter {         
          testimonials {
            headline
            text
            elements {
              text
              person_name
              person_image {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              company_name
              company_url
            }
          }       
        }
      }
    }
  }
`;
