import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import Img from 'gatsby-image';

import * as classes from './SkillSection.module.css';

const SkillSection = (props) => {
  if (!props.content?.skills?.headline || !props.content?.skills?.elements?.length) {
    return null;
  }

  return (
    <section className="section-content accent-soft">
      <Container fluid>
        <h2 className="section-headline" dangerouslySetInnerHTML={{ __html: props.content.skills.headline }} />
        {props.content?.skills?.text && (
          <p
            className={classes.SkillText}
            dangerouslySetInnerHTML={{ __html: props.content.skills.text }}
            style={{ marginBottom: '80px' }}
          />
        )}

        <div className={classes.SkillWrapper}>
          <Row>
            {props.content.skills.elements.map((skill) => (
              <Col xs={6} md={4} lg={true} className="text-center p-3">
                <a key={skill.name} href={skill.url} target="_blank" rel="noopener noreferrer">
                  {skill.image.extension === 'svg' ? (
                    <img src={skill.image.publicURL} alt={skill.name} className={classes.Skill} />
                  ) : (
                    // TODO Fix raster based images in Skill CSS class
                    <Img fluid={skill.image.childImageSharp.fluid} alt={skill.name} className={classes.Skill} />
                  )}
                </a>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
};

SkillSection.propTypes = {
  content: PropTypes.array.isRequired,
};

export default SkillSection;
